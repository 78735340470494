/*=============== REQUEST FUNC ===============*/
async function makePostRequest(path, data = {}) {
    try {
        let result = await axios.post(path, data, {timeout: 5000, headers: {'Content-Type': 'application/json'}})
        return {"response": result.data, "code": result.status}
    } catch(error){
        console.error(error);
        let result
        if (error.response === undefined) {
            result = {"response": error.data, "code": error.status};
        } else if (error.code === 'ECONNABORTED') {
            result = {"response": {status: 408, status_code: "Timeout", detail: "timeout"}, "code": 408};
        } else {
            result = {"response": error.response.data, "code": error.response.status};
        }
        return result
    }
}


async function makeGetRequest(path) {
    try {
        let result = await axios.get(path, {timeout: 5000, headers: {'Content-Type': 'application/json'}})
        return {"response": result.data, "code": result.status}
    } catch(error){
        let result
        if (error.response === undefined) {
            result = {"response": error.data, "code": error.status};
        } else if (error.code === 'ECONNABORTED') {
            result = {"response": {status: 408, status_code: "Timeout", detail: "timeout"}, "code": 408};
        } else {
            result = {"response": error.response.data, "code": error.response.status};
        }
        return result
    }
}

async function makeGetRequestImage(path, data = {}) {
    try {
        let result = await axios.get(path, {timeout: 5000, responseType: 'blob'});
        return {"response": result.data, "code": result.status, "headers": result.headers}
    } catch(error){
        console.error(error);
        let result
        if (error.response === undefined) {
            result = {"response": error.data, "code": error.status};
        } else if (error.code === 'ECONNABORTED') {
            result = {"response": {status: 408, status_code: "Timeout", detail: "timeout"}, "code": 408};
        } else {
            result = {"response": error.response.data, "code": error.response.status};
        }
        return result
    }
}